// ./config.js
export const firebaseConfig = {
  apiKey: "AIzaSyA_TlmdB04khS0_86U-iEHdslgYSFlF45A",
  authDomain: "pixpal-1707752767387.firebaseapp.com",
  projectId: "pixpal-1707752767387",
  storageBucket: "pixpal-1707752767387.appspot.com",
  messagingSenderId: "42814286164",
  appId: "1:42814286164:web:6e9a0e2e3e2fe3366780c1",
  measurementId: "G-PGBQ5F2RNC"
};
